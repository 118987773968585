import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="hero-header">
        <div className="headline">{data.site.siteMetadata.home.title}</div>
        <div
          className="primary-content"
          dangerouslySetInnerHTML={{
            __html: data.site.siteMetadata.home.description
          }}
        />
        <div className="primary-content" style={{ marginBottom: 20 }}>
          <a
            href="https://drive.google.com/file/d/1-JcaBsyZw4DStPwBL8RyoYintZ7wXX6I/view?usp=sharing"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            My CV 📜
          </a>
        </div>
        <Link to="/contact" className="button -primary">
          Get in touch &rarr;
        </Link>
      </div>
    )}
  />
);
